<template>
  <div>
    <div class="my-30 py-40 center">
      <b-spinner :variant="$route.params.status === 'Success' ? 'success' : 'danger'"></b-spinner>
      <p :class="`mt-5 color-${$route.params.status === 'Success' ? 'blue' : 'red'}`">{{ $route.params.status }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StripeReturn',
  mounted() {
    const redirect_status = this.$route.query.redirect_status || 'unknown';
    const paymentMethod = localStorage.getItem('eventboxPaymentMethod');
    if (redirect_status === 'pending' || paymentMethod === 'sofort' || paymentMethod === 'sepa_debit') {
      this.$router.push(`/payment/confirm/Pending`);
    } else if (redirect_status === 'succeeded') {
      this.$router.push(`/payment/confirm/Success`);
    } else {
      this.$router.push(`/payment/confirm/${redirect_status}`);
    }
  }
};
</script>